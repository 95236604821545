<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="#">
        <img class="navbar-brand-full" src="img/brand/g2lab.png" width="89" height="25" alt="G2lab" />
        <img class="navbar-brand-minimized" src="img/brand/sygnet.svg" width="30" height="30" alt="CoreUI Logo" />
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" />
      <!--b-navbar-nav class="d-md-down-none">
        <b-nav-item class="px-3" to="/dashboard">Dashboard</b-nav-item>
        <b-nav-item class="px-3" to="/users" exact>Users</b-nav-item>
        <b-nav-item class="px-3">Settings</b-nav-item>
      </b-navbar-nav-->
      <b-navbar-nav class="ml-auto">
        <!--b-nav-item class="d-md-down-none">
          <i class="icon-bell"></i>
          <b-badge pill variant="danger">5</b-badge>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <i class="icon-list"></i>
        </b-nav-item>
        <b-nav-item class="d-md-down-none">
          <i class="icon-location-pin"></i>
        </b-nav-item-->
        <DefaultHeaderDropdownAccnt />
      </b-navbar-nav>
      <!--AsideToggler class="d-none d-lg-block" /-->
      <!--<AsideToggler class="d-lg-none" mobile />-->
    </AppHeader>
    <div class="app-body">
      <AppSidebar fixed>
        <SidebarHeader />
        <SidebarForm />
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter />
        <SidebarMinimizer />
      </AppSidebar>
      <main class="main">
        <!--        <Breadcrumb :list="list"/>-->
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside fixed>
        <!--aside-->
        <DefaultAside />
      </AppAside>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <a href="https://g2academy.co">G2academy.co</a>
        <span class="ml-1">&copy; 2021 Development.</span>
      </div>
      <!--      <div class="ml-auto">-->
      <!--        <span class="mr-1">Powered by</span>-->
      <!--        <a href="https://coreui.io">CoreUI for Vue</a>-->
      <!--      </div>-->
    </TheFooter>

    <Loading v-if="pageLoading" />
  </div>
</template>

<script>
import nav from '@/_nav'
import {
  Header as AppHeader,
  SidebarToggler,
  Sidebar as AppSidebar,
  SidebarFooter,
  SidebarForm,
  SidebarHeader,
  SidebarMinimizer,
  SidebarNav,
  Aside as AppAside,
  Footer as TheFooter,
  Breadcrumb
} from '@coreui/vue'
import DefaultAside from './DefaultAside'
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
import { mapGetters } from 'vuex'
export default {
  name: 'DefaultContainer',
  components: {
    AppHeader,
    AppSidebar,
    AppAside,
    TheFooter,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    Loading: () => import(/* webpackChunkName: "Loading" */ '@/components/Illustration/Loading')
  },
  data() {
    return {
      nav: nav.items
    }
  },
  computed: {
    ...mapGetters('common', ['pageLoading']),
    name() {
      return this.$route.name
    },
    list() {
      return this.$route.matched.filter(route => route.name || route.meta.label)
    }
  }
}
</script>
