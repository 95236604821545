var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app"
  }, [_c('AppHeader', {
    attrs: {
      "fixed": ""
    }
  }, [_c('SidebarToggler', {
    staticClass: "d-lg-none",
    attrs: {
      "display": "md",
      "mobile": ""
    }
  }), _c('b-link', {
    staticClass: "navbar-brand",
    attrs: {
      "to": "#"
    }
  }, [_c('img', {
    staticClass: "navbar-brand-full",
    attrs: {
      "src": "img/brand/g2lab.png",
      "width": "89",
      "height": "25",
      "alt": "G2lab"
    }
  }), _c('img', {
    staticClass: "navbar-brand-minimized",
    attrs: {
      "src": "img/brand/sygnet.svg",
      "width": "30",
      "height": "30",
      "alt": "CoreUI Logo"
    }
  })]), _c('SidebarToggler', {
    staticClass: "d-md-down-none",
    attrs: {
      "display": "lg"
    }
  }), _c('b-navbar-nav', {
    staticClass: "ml-auto"
  }, [_c('DefaultHeaderDropdownAccnt')], 1)], 1), _c('div', {
    staticClass: "app-body"
  }, [_c('AppSidebar', {
    attrs: {
      "fixed": ""
    }
  }, [_c('SidebarHeader'), _c('SidebarForm'), _c('SidebarNav', {
    attrs: {
      "navItems": _vm.nav
    }
  }), _c('SidebarFooter'), _c('SidebarMinimizer')], 1), _c('main', {
    staticClass: "main"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('router-view')], 1)]), _c('AppAside', {
    attrs: {
      "fixed": ""
    }
  }, [_c('DefaultAside')], 1)], 1), _c('TheFooter', [_c('div', [_c('a', {
    attrs: {
      "href": "https://g2academy.co"
    }
  }, [_vm._v("G2academy.co")]), _c('span', {
    staticClass: "ml-1"
  }, [_vm._v("© 2021 Development.")])])]), _vm.pageLoading ? _c('Loading') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }