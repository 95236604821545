var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', [_c('b-tab', [_c('template', {
    slot: "title"
  }, [_c('i', {
    staticClass: "icon-list"
  })]), _c('b-list-group', {
    staticClass: "list-group-accent"
  }, [_c('b-list-group-item', {
    staticClass: "list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small"
  }, [_vm._v(" Today ")]), _c('b-list-group-item', {
    staticClass: "list-group-item-accent-warning list-group-item-divider",
    attrs: {
      "href": "#"
    }
  }, [_c('div', {
    staticClass: "avatar float-right"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "/img/avatars/7.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('div', [_vm._v("Meeting with "), _c('strong', [_vm._v("Lucas")])]), _c('small', {
    staticClass: "text-muted mr-3"
  }, [_c('i', {
    staticClass: "icon-calendar"
  }), _vm._v("  1 - 3pm ")]), _c('small', {
    staticClass: "text-muted"
  }, [_c('i', {
    staticClass: "icon-location-pin"
  }), _vm._v("  Palo Alto, CA ")])]), _c('b-list-group-item', {
    staticClass: "list-group-item-accent-info",
    attrs: {
      "href": "#"
    }
  }, [_c('div', {
    staticClass: "avatar float-right"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/4.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('div', [_vm._v("Skype with "), _c('strong', [_vm._v("Megan")])]), _c('small', {
    staticClass: "text-muted mr-3"
  }, [_c('i', {
    staticClass: "icon-calendar"
  }), _vm._v("  4 - 5pm")]), _c('small', {
    staticClass: "text-muted"
  }, [_c('i', {
    staticClass: "icon-social-skype"
  }), _vm._v("  On-line")])]), _c('hr', {
    staticClass: "transparent mx-3 my-0"
  }), _c('b-list-group-item', {
    staticClass: "list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small"
  }, [_vm._v(" Tomorrow ")]), _c('b-list-group-item', {
    staticClass: "list-group-item-accent-danger list-group-item-divider",
    attrs: {
      "href": "#"
    }
  }, [_c('div', [_vm._v("New UI Project - "), _c('strong', [_vm._v("deadline")])]), _c('small', {
    staticClass: "text-muted mr-3"
  }, [_c('i', {
    staticClass: "icon-calendar"
  }), _vm._v("  10 - 11pm")]), _c('small', {
    staticClass: "text-muted"
  }, [_c('i', {
    staticClass: "icon-home"
  }), _vm._v("  creativeLabs HQ")]), _c('div', {
    staticClass: "avatars-stack mt-2"
  }, [_c('div', {
    staticClass: "avatar avatar-xs"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/2.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('div', {
    staticClass: "avatar avatar-xs"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/3.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('div', {
    staticClass: "avatar avatar-xs"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/4.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('div', {
    staticClass: "avatar avatar-xs"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/5.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('div', {
    staticClass: "avatar avatar-xs"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/6.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  })])])]), _c('b-list-group-item', {
    staticClass: "list-group-item-accent-success list-group-item-divider",
    attrs: {
      "href": "#"
    }
  }, [_c('div', [_c('strong', [_vm._v("#10 Startups.Garden")]), _vm._v(" Meetup")]), _c('small', {
    staticClass: "text-muted mr-3"
  }, [_c('i', {
    staticClass: "icon-calendar"
  }), _vm._v("  1 - 3pm")]), _c('small', {
    staticClass: "text-muted"
  }, [_c('i', {
    staticClass: "icon-location-pin"
  }), _vm._v("  Palo Alto, CA")])]), _c('b-list-group-item', {
    staticClass: "list-group-item-accent-primary list-group-item-divider",
    attrs: {
      "href": "#"
    }
  }, [_c('div', [_c('strong', [_vm._v("Team meeting")])]), _c('small', {
    staticClass: "text-muted mr-3"
  }, [_c('i', {
    staticClass: "icon-calendar"
  }), _vm._v("  4 - 6pm")]), _c('small', {
    staticClass: "text-muted"
  }, [_c('i', {
    staticClass: "icon-home"
  }), _vm._v("  creativeLabs HQ")]), _c('div', {
    staticClass: "avatars-stack mt-2"
  }, [_c('div', {
    staticClass: "avatar avatar-xs"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/2.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('div', {
    staticClass: "avatar avatar-xs"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/3.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('div', {
    staticClass: "avatar avatar-xs"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/4.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('div', {
    staticClass: "avatar avatar-xs"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/5.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('div', {
    staticClass: "avatar avatar-xs"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/6.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('div', {
    staticClass: "avatar avatar-xs"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/7.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  })]), _c('div', {
    staticClass: "avatar avatar-xs"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/8.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  })])])])], 1)], 2), _c('b-tab', [_c('template', {
    slot: "title"
  }, [_c('i', {
    staticClass: "icon-speech"
  })]), _c('div', {
    staticClass: "p-3"
  }, [_c('div', {
    staticClass: "message"
  }, [_c('div', {
    staticClass: "py-3 pb-5 mr-3 float-left"
  }, [_c('div', {
    staticClass: "avatar"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/7.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  }), _c('b-badge', {
    staticClass: "avatar-status",
    attrs: {
      "variant": "success"
    }
  })], 1)]), _c('div', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lukasz Holeczek")]), _c('small', {
    staticClass: "text-muted float-right mt-1"
  }, [_vm._v("1:52 PM")])]), _c('div', {
    staticClass: "text-truncate font-weight-bold"
  }, [_vm._v("Lorem ipsum dolor sit amet")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...")])]), _c('hr'), _c('div', {
    staticClass: "message"
  }, [_c('div', {
    staticClass: "py-3 pb-5 mr-3 float-left"
  }, [_c('div', {
    staticClass: "avatar"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/7.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  }), _c('b-badge', {
    staticClass: "avatar-status",
    attrs: {
      "variant": "danger"
    }
  })], 1)]), _c('div', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lukasz Holeczek")]), _c('small', {
    staticClass: "text-muted float-right mt-1"
  }, [_vm._v("1:52 PM")])]), _c('div', {
    staticClass: "text-truncate font-weight-bold"
  }, [_vm._v("Lorem ipsum dolor sit amet")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...")])]), _c('hr'), _c('div', {
    staticClass: "message"
  }, [_c('div', {
    staticClass: "py-3 pb-5 mr-3 float-left"
  }, [_c('div', {
    staticClass: "avatar"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/7.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  }), _c('b-badge', {
    staticClass: "avatar-status",
    attrs: {
      "variant": "info"
    }
  })], 1)]), _c('div', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lukasz Holeczek")]), _c('small', {
    staticClass: "text-muted float-right mt-1"
  }, [_vm._v("1:52 PM")])]), _c('div', {
    staticClass: "text-truncate font-weight-bold"
  }, [_vm._v("Lorem ipsum dolor sit amet")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...")])]), _c('hr'), _c('div', {
    staticClass: "message"
  }, [_c('div', {
    staticClass: "py-3 pb-5 mr-3 float-left"
  }, [_c('div', {
    staticClass: "avatar"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/7.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  }), _c('b-badge', {
    staticClass: "avatar-status",
    attrs: {
      "variant": "warning"
    }
  })], 1)]), _c('div', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lukasz Holeczek")]), _c('small', {
    staticClass: "text-muted float-right mt-1"
  }, [_vm._v("1:52 PM")])]), _c('div', {
    staticClass: "text-truncate font-weight-bold"
  }, [_vm._v("Lorem ipsum dolor sit amet")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...")])]), _c('hr'), _c('div', {
    staticClass: "message"
  }, [_c('div', {
    staticClass: "py-3 pb-5 mr-3 float-left"
  }, [_c('div', {
    staticClass: "avatar"
  }, [_c('img', {
    staticClass: "img-avatar",
    attrs: {
      "src": "img/avatars/7.jpg",
      "alt": "admin@bootstrapmaster.com"
    }
  }), _c('b-badge', {
    staticClass: "avatar-status",
    attrs: {
      "variant": "dark"
    }
  })], 1)]), _c('div', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lukasz Holeczek")]), _c('small', {
    staticClass: "text-muted float-right mt-1"
  }, [_vm._v("1:52 PM")])]), _c('div', {
    staticClass: "text-truncate font-weight-bold"
  }, [_vm._v("Lorem ipsum dolor sit amet")]), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...")])])])], 2), _c('b-tab', [_c('template', {
    slot: "title"
  }, [_c('i', {
    staticClass: "icon-settings"
  })]), _c('div', {
    staticClass: "p-3"
  }, [_c('h6', [_vm._v("Settings4")]), _c('div', {
    staticClass: "aside-options"
  }, [_c('div', {
    staticClass: "clearfix mt-4"
  }, [_c('small', [_c('b', [_vm._v("Option 1")])]), _c('c-switch', {
    staticClass: "float-right",
    attrs: {
      "color": "success",
      "label": "",
      "variant": "pill",
      "size": "sm",
      "checked": ""
    }
  })], 1), _c('div', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.")])])]), _c('div', {
    staticClass: "aside-options"
  }, [_c('div', {
    staticClass: "clearfix mt-3"
  }, [_c('small', [_c('b', [_vm._v("Option 2")])]), _c('c-switch', {
    staticClass: "float-right",
    attrs: {
      "color": "success",
      "label": "",
      "variant": "pill",
      "size": "sm"
    }
  })], 1), _c('div', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.")])])]), _c('div', {
    staticClass: "aside-options"
  }, [_c('div', {
    staticClass: "clearfix mt-3"
  }, [_c('small', [_c('b', [_vm._v("Option 3")])]), _c('c-switch', {
    staticClass: "float-right",
    attrs: {
      "color": "success",
      "label": "",
      "variant": "pill",
      "size": "sm",
      "disabled": "",
      "defaultChecked": ""
    }
  })], 1), _c('div', [_c('small', {
    staticClass: "text-muted"
  }, [_vm._v("Disabled option.")])])]), _c('div', {
    staticClass: "aside-options"
  }, [_c('div', {
    staticClass: "clearfix mt-3"
  }, [_c('small', [_c('b', [_vm._v("Option 4")])]), _c('c-switch', {
    staticClass: "float-right",
    attrs: {
      "color": "success",
      "label": "",
      "variant": "pill",
      "size": "sm",
      "checked": ""
    }
  })], 1)]), _c('hr'), _c('h6', [_vm._v("System Utilization")]), _c('div', {
    staticClass: "text-uppercase mb-1 mt-4"
  }, [_c('small', [_c('b', [_vm._v("CPU Usage")])])]), _c('b-progress', {
    staticClass: "progress-xs",
    attrs: {
      "height": "{}",
      "variant": "info",
      "value": 25
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("348 Processes. 1/4 Cores.")]), _c('div', {
    staticClass: "text-uppercase mb-1 mt-2"
  }, [_c('small', [_c('b', [_vm._v("Memory Usage")])])]), _c('b-progress', {
    staticClass: "progress-xs",
    attrs: {
      "height": "{}",
      "variant": "warning",
      "value": 70
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("11444GB/16384MB")]), _c('div', {
    staticClass: "text-uppercase mb-1 mt-2"
  }, [_c('small', [_c('b', [_vm._v("SSD 1 Usage")])])]), _c('b-progress', {
    staticClass: "progress-xs",
    attrs: {
      "height": "{}",
      "variant": "danger",
      "value": 95
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("243GB/256GB")]), _c('div', {
    staticClass: "text-uppercase mb-1 mt-2"
  }, [_c('small', [_c('b', [_vm._v("SSD 2 Usage")])])]), _c('b-progress', {
    staticClass: "progress-xs",
    attrs: {
      "height": "{}",
      "variant": "success",
      "value": 10
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v("25GB/256GB")])], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }