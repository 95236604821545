<template>
  <AppHeaderDropdown right no-caret>
    {{ this.$store.getters['general/generals'] }}
    <template slot="header">
      <div class="flex items-center gap-2 pr-4">
        <User color="yellow-primary" height="40" width="40" />
        <div class="text-left">
          <p class="font-bold text-base mb-0">{{ customer.name }}</p>
          <p class="text-xs mb-0" v-if="customer && customer.customerRoles">
            {{ customer.customerRoles.length > 0 && customer.customerRoles[0].customer.name }}
          </p>
        </div>
        <Chevron direction="down" width="12" />
      </div>
    </template>
    <!--    <template slot="dropdown">-->
    <!--      <b-dropdown-item href="" @click="profile"><i class="fa fa-lock" /> Profile</b-dropdown-item>-->
    <!--    </template>-->
    <template slot="dropdown">
      <b-dropdown-item href="/change-password"><i class="fa fa-bell" /> Change Password</b-dropdown-item>
      <b-dropdown-item href="" @click="logout"><i class="fa fa-lock" /> Logout</b-dropdown-item>
    </template>
  </AppHeaderDropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import { HeaderDropdown as AppHeaderDropdown } from '@coreui/vue'
export default {
  name: 'DefaultHeaderDropdownAccnt',
  components: {
    AppHeaderDropdown,
    User: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/User'),
    Chevron: () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Chevron')
  },
  data: () => {
    return { itemsCount: 42 }
  },
  computed: {
    ...mapGetters('customer', ['customer'])
  },
  methods: {
    logout: function () {
      self = this
      this.$store.dispatch('customer/LOGOUT').then(function (resp) {
        location.reload()
      })
    },
    profile: function () {
      this.$router.push('/customer/' + this.$store.getters['customer/customer'].id)
    }
  }
}
</script>
