var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AppHeaderDropdown', {
    attrs: {
      "right": "",
      "no-caret": ""
    }
  }, [_vm._v(" " + _vm._s(this.$store.getters['general/generals']) + " "), _c('template', {
    slot: "header"
  }, [_c('div', {
    staticClass: "flex items-center gap-2 pr-4"
  }, [_c('User', {
    attrs: {
      "color": "yellow-primary",
      "height": "40",
      "width": "40"
    }
  }), _c('div', {
    staticClass: "text-left"
  }, [_c('p', {
    staticClass: "font-bold text-base mb-0"
  }, [_vm._v(_vm._s(_vm.customer.name))]), _vm.customer && _vm.customer.customerRoles ? _c('p', {
    staticClass: "text-xs mb-0"
  }, [_vm._v(" " + _vm._s(_vm.customer.customerRoles.length > 0 && _vm.customer.customerRoles[0].customer.name) + " ")]) : _vm._e()]), _c('Chevron', {
    attrs: {
      "direction": "down",
      "width": "12"
    }
  })], 1)]), _c('template', {
    slot: "dropdown"
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "/change-password"
    }
  }, [_c('i', {
    staticClass: "fa fa-bell"
  }), _vm._v(" Change Password")]), _c('b-dropdown-item', {
    attrs: {
      "href": ""
    },
    on: {
      "click": _vm.logout
    }
  }, [_c('i', {
    staticClass: "fa fa-lock"
  }), _vm._v(" Logout")])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }